import { isNil } from 'ramda'

import FilePdf from '~/src/components/generic/PhosphorIcons/FilePdfIcon'

import {
  DEFAULT_TIME_ZONE_NAME,
  DOCUMENT_TYPE_LABELS,
  LEG_COLORS,
  OPERATIONAL_STOP,
} from '~/src/components/shared/features/constants'

import { convertToTimezone, diffInSeconds } from '~/helpers/date'
import { endOfDay, startOfDay } from '~/helpers/time'
import { formatAddress } from '~/helpers/location'

import {
  Document,
  DocumentExtension,
  DocumentType,
  GpsPoint,
  SortInput,
} from '~/__generated_types__/globalTypes'

// TODO: Add tests for all of these helper functions missing them:
// https://app.asana.com/0/1206283941353593/1206676201369455
const getOppositeDirection = (direction: string): SortInput => {
  if (direction === SortInput.None) {
    return SortInput.Asc
  } else if (direction === SortInput.Asc) {
    return SortInput.Desc
  }

  return SortInput.None
}

const capitalize = (str: string): string => {
  const lowerCaseString = str.toLowerCase()
  return lowerCaseString.charAt(0).toUpperCase() + lowerCaseString.slice(1)
}

const getBestProofOfDeliveryDoc = (docs: Array<Document>): Document | null => {
  const podDocs = docs.filter((doc) => doc.type === DocumentType.ProofOfDelivery)
  if (podDocs.length === 0) return null
  podDocs.sort((docA, docB) => {
    const isEpodA = docA.name?.toUpperCase().includes('EPOD')
    const isEpodB = docB.name?.toUpperCase().includes('EPOD')

    if (isEpodA && !isEpodB) return -1
    if (!isEpodA && isEpodB) return 1

    if (docA.createdAt && docB.createdAt)
      return Date.parse(docB.createdAt) - Date.parse(docA.createdAt)
    else return 0
  })
  return podDocs[0]
}

const getDocumentTypeLabel = (docType: DocumentType) => {
  if (docType in DOCUMENT_TYPE_LABELS) {
    return DOCUMENT_TYPE_LABELS[docType]
  }

  return ''
}

const getDocumentFilterType = (docType?: DocumentType | null) => {
  if (
    docType === DocumentType.ProofOfDelivery ||
    docType === DocumentType.BillOfLading
  ) {
    return docType
  }

  return DocumentType.Other
}

const getDocumentIcon = (docExtension: DocumentExtension) => {
  switch (docExtension) {
    case DocumentExtension.Pdf:
      return FilePdf
  }
}

const getLocaleTimeString = (time: Date | string | undefined | null) => {
  const options: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    hourCycle: 'h23',
  }

  if (time instanceof Date) {
    return time.toLocaleTimeString('en-US', options)
  }

  return time ? new Date(time).toLocaleTimeString('en-US', options) : undefined
}

const getLocaleDateString = (
  date: Date | string | undefined | null,
  option?: Intl.DateTimeFormatOptions
): string | undefined => {
  const defaultOption: Intl.DateTimeFormatOptions = {
    month: '2-digit',
    day: '2-digit',
  }

  if (date instanceof Date) {
    date.toLocaleDateString('en-US', option ?? defaultOption)
  }

  return date
    ? new Date(date).toLocaleDateString('en-US', option ?? defaultOption)
    : undefined
}

const getDwellTime = (
  arrivalTime: string | undefined | null,
  departureTime: string | undefined | null,
  estimatedArrivalTime: string | undefined | null,
  estimatedDepartureTime: string | undefined | null
): { dwellTime: number | undefined; isEstimate: boolean } => {
  let dwellTime: number | undefined = undefined
  let isEstimate = false

  if (arrivalTime && departureTime) {
    dwellTime = diffInSeconds(new Date(departureTime), new Date(arrivalTime))
  } else if (arrivalTime && estimatedDepartureTime) {
    dwellTime = diffInSeconds(new Date(estimatedDepartureTime), new Date(arrivalTime))
    isEstimate = true
  } else if (estimatedArrivalTime && estimatedDepartureTime) {
    dwellTime = diffInSeconds(
      new Date(estimatedDepartureTime),
      new Date(estimatedArrivalTime)
    )
    isEstimate = true
  }

  return { dwellTime, isEstimate }
}

const getLegTotalTime = (
  startTime: string | undefined | null,
  endTime: string | undefined | null,
  estimatedTripTime: number
): number => {
  if (endTime && startTime) {
    return diffInSeconds(new Date(endTime), new Date(startTime))
  }

  return estimatedTripTime
}

const getLocalTime = (
  time: string | undefined | null,
  timeZoneName: string | undefined
): string | undefined => {
  const localTime =
    time && convertToTimezone(time, timeZoneName ?? DEFAULT_TIME_ZONE_NAME)

  return localTime ?? undefined
}

/**
 * Helper that gets the ISO string representation of the current time minus 24 hours.
 *
 * @returns {string} The ISO string representation of the time 24 hours ago.
 */
const getNotificationTimeFloor = (): string => {
  return new Date(new Date().getTime() - 24 * 60 * 60 * 1000).toISOString()
}

const getLegColor = (legIdx: number) => LEG_COLORS[legIdx % LEG_COLORS.length].hexColor

const getSortingColumnName = (sortEnum: string): string => {
  const [...sortingColumnParts] = (sortEnum || '').split('_')
  return sortingColumnParts.slice(0, sortingColumnParts.length - 1).join('_')
}

/**
 * helper function to get the date range
 */
const getWeekDateRange = ({
  startOffset = 0,
  endOffset = 0,
}: {
  startOffset?: number
  endOffset?: number
}): {
  start: string
  end: string
} => {
  const today = new Date()

  const startDateTime = startOfDay(today)
  const endDateTime = endOfDay(today)
  const startDate = new Date(
    startDateTime.setDate(startDateTime.getDate() - startOffset)
  )
  const endDate = new Date(endDateTime.setDate(endDateTime.getDate() + endOffset))

  return { start: startDate.toISOString(), end: endDate.toISOString() }
}

const getLoadEmptyStateMessage = (isFilterEmpty: boolean) =>
  isFilterEmpty
    ? 'Look up a single load by typing in Ryder # or Reference # in a search bar, or view a list of loads by selecting cost centers and/or other filters.'
    : 'There are no loads based on your applied filters. Adjust your filters or search by reference number to view loads.'

const isOperationalStop = (locationName: string | null | undefined) => {
  return !locationName || locationName === OPERATIONAL_STOP
}

const formatOperationalStopAddress = (
  address:
    | {
        line1?: string | null
        line2?: string | null
        city?: string | null
        stateOrProvinceAbbrev?: string | null
        postalCode?: string | null
      }
    | null
    | undefined,
  point: GpsPoint | null | undefined
) => {
  if (!address && !point) return '\u2014'

  const formattedAddress = formatAddress(address)

  if (point && !address?.line1 && !address?.line2) {
    const latLon = `(${point.lat.toFixed(5)}, ${point.lon.toFixed(5)})`
    return `${formattedAddress} ${latLon}`
  }

  return formattedAddress
}

const isStopLate = (
  estimatedArrivalTime: string | null | undefined,
  latenessThresholdTime: string | null | undefined,
  appointmentEndTime: string | null | undefined
): boolean => {
  if (isNil(estimatedArrivalTime)) return false

  const estimatedArrival = new Date(estimatedArrivalTime)
  const currentTime = new Date()

  // Check if ETA is past the lateness threshold
  const isRunningLate =
    !isNil(latenessThresholdTime) && estimatedArrival > new Date(latenessThresholdTime)

  // Check if current time is past the appointment end time
  const isCurrentTimePastAppointment =
    !isNil(appointmentEndTime) && currentTime > new Date(appointmentEndTime)

  return isRunningLate || isCurrentTimePastAppointment
}

const prettyDuration = (durationSeconds: number): string => {
  if (durationSeconds <= 0) return ''

  const days = Math.floor(durationSeconds / (24 * 60 * 60))
  const hours = Math.floor((durationSeconds % (24 * 60 * 60)) / (60 * 60))
  const minutes = Math.floor((durationSeconds % (60 * 60)) / 60)

  const parts = []
  if (days > 0) parts.push(`${days}d`)
  if (hours > 0) parts.push(`${hours}h`)
  if (minutes > 0) parts.push(`${minutes}m`)

  return parts.length > 0 ? `${parts.join(' ')}` : ''
}

export {
  capitalize,
  getBestProofOfDeliveryDoc,
  getDocumentFilterType,
  getDocumentIcon,
  getDocumentTypeLabel,
  getLocaleDateString,
  getLocaleTimeString,
  getOppositeDirection,
  getDwellTime,
  getLegTotalTime,
  getLocalTime,
  getNotificationTimeFloor,
  getLegColor,
  getSortingColumnName,
  getWeekDateRange,
  getLoadEmptyStateMessage,
  isOperationalStop,
  formatOperationalStopAddress,
  isStopLate,
  prettyDuration,
}
